export default [
  {
    name: "Home",
    path: "/",
    neverContain: true,
  },
  {
    name: "Search Tips",
    path: "/tips/",
  },
  // {
  //   name: "Languages",
  //   path: "/language/",
  //   canContain: [/^\/language\/.+/],
  // },
  // {
  //   name: "Phrase",
  //   path: "/phrase/",
  //   canContain: [/^\/phrase\/.+/],
  // },
  // {
  //   name: "Request a phrase",
  //   path: "/request/",
  // },
]
