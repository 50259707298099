import React from "react"
import { StaticQuery, graphql, Link, navigate } from "gatsby"

import SearchInput from "../SearchInput"
import IconButton from "../IconButton"

import withLocation from "../../../lib/HOC/withLocation"
import { useHasScrollbar } from "../../lib/hooks"

import css from "./Header.module.scss"
import utilities from "../../styles/utilities.module.scss"

const notRoot = currentLocation => currentLocation !== "/"

const locationEqual = (itemLocation, currentLocation) =>
  itemLocation.replace(/\/$/, "") === currentLocation.replace(/\/$/, "");

const locationContains = (
  itemLocation,
  currentLocation,
  canContain = [],
  neverContain = false
) =>
  !neverContain &&
  (currentLocation.indexOf(itemLocation) === 0 ||
    (canContain.length > 0 &&
      canContain.filter(re => {
        const matches = currentLocation.match(re)
        return matches && matches.length
      }).length > 0))

const MenuLink = ({
  className,
  path,
  name,
  children,
  locationEqual = false,
  locationContains = false,
}) => {
  return (
    <li className={className}>
      <Link
        to={path}
        className={[
          css.menuTreeLink,
          locationContains ? css.menuTreeLinkChild : "",
          locationEqual ? css.menuTreeLinkActive : "",
        ].join(" ")}
      >
        {name}
      </Link>
      {children}
    </li>
  )
}

const renderMenuTree = (menuTree = [], depth = 0, pathname = null) => {
  return (
    <ul className={css.menuTreeList} data-menu-tree-depth={depth}>
      {menuTree.map((item, index) => (
        <MenuLink
          key={index}
          className={css.menuTreeListItem}
          path={item.path}
          name={item.name}
          locationContains={
            notRoot(pathname) &&
            locationContains(
              item.path,
              pathname,
              item.canContain,
              item.neverContain || false
            )
          }
          locationEqual={
            notRoot(pathname) && locationEqual(item.path, pathname)
          }
        >
          {item.children &&
            item.children.length > 0 &&
            renderMenuTree(item.children, depth + 1, pathname)}
        </MenuLink>
      ))}
    </ul>
  )
}

type DataProps = {
  data?: any;
};

type Props = {
  location?: any;
  menuTree?: any[];
  showSearch?: boolean;
};

export const PureHeader: React.FunctionComponent<Props & DataProps> = ({
  location,
  menuTree = [],
  data = {},
  showSearch = true,
}) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  const hasScrollbar = useHasScrollbar()
  const { pathname } = location || {}

  const onSubmitSearch = (query, lang) => {
    navigate(`/search?search=${query}&lang=${lang}`, {})
  }

  return (
    <header className={css.header} role="banner">
      <a
        href="https://www.sl.nsw.gov.au"
        title="State Library of NSW"
        className={css.slnswLogoWrapper}
      >
        <img
          src="https://www.sl.nsw.gov.au/sites/all/themes/slnsw_frontend/slnsw_logo_horizontal_white.svg"
          alt="State Library of NSW logo"
          title="State Library of NSW"
          className={css.slnswLogo}
          loading="lazy"
        />
      </a>
      <div className={css.siteNameLinkWrapper}>
        <Link to="/" className={css.siteNameLink}>
          {data?.site?.siteMetadata?.title || 'Sitename'}
        </Link>
      </div>
      <div className={[css.headerRight, css.menuContainer].join(" ")}>
        <div
          className={[
            css.menuArea,
            hasScrollbar ? css.menuAreaWithScrollbar : "",
            menuOpen ? css.menuAreaOpen : "",
          ].join(" ")}
          aria-hidden={!menuOpen}
        >
          {menuTree.length > 0 && (
            <nav className={css.menuTreeContainer}>
              {renderMenuTree(menuTree, 0, pathname)}
            </nav>
          )}
          {showSearch && <SearchInput variant="dark" onSubmit={onSubmitSearch} />}
        </div>

        <IconButton
          className={css.menuButton}
          iconName={menuOpen ? "close" : "hamburger"}
          iconVariant="white"
          size="lg"
          onClick={() => setMenuOpen(!menuOpen)}
          aria-expanded={menuOpen}
        >
          <span className={utilities.screenreaderOnly}>Toggle menu</span>
        </IconButton>
      </div>
    </header>
  );
};

const Header = ({ location, menuTree = [], showSearch = true }) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data: any) => <PureHeader location={location} menuTree={menuTree} data={data} showSearch={showSearch} />}
  />
)

export default withLocation(Header)
