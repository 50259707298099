export default {
  "corp-site-hp": {
    name: "Home",
    link: "https://www.sl.nsw.gov.au/",
  },
  "corp-site-rc": {
    name: "Research and Collections",
    link: "https://www.sl.nsw.gov.au/research-and-collections",
  },
}
