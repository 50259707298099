import React from "react"

import CollapseSection from "../CollapseSection"

import css from "./Footer.module.scss"

const Footer = ({ data }) => (
  <footer className={css.footerWrap} role="contentinfo">
    <div className={css.footer}>
      <a
        href="https://www.sl.nsw.gov.au"
        title="State Library of NSW"
        className={css.slnswLogoWrapper}
      >
        <img
          src="https://www.sl.nsw.gov.au/sites/all/themes/slnsw_frontend/slnsw_logo_horizontal_white.svg"
          alt="State Library of NSW logo"
          title="State Library of NSW"
          className={css.slnswLogo}
          loading="lazy"
        />
      </a>
      <div className={css.column}>
        <section>
          <h3 className={css.sectionHeading}>
            <a href="https://www.sl.nsw.gov.au/contact-us">Contact Us</a>
          </h3>

          <div className="block-content">
            <div className="adr">
              <div className="street-address">
                State Library of NSW
              </div>
              <div className="street-address">1 Shakespeare Place</div>
              <span className="locality">Sydney</span>{" "}
              <span className="region">NSW</span>{" "}
              <span className="postal-code">2000</span>{" "}
              <span className="country-name">Australia</span>
            </div>
            <div className="tel">+61 2 9273 1414</div>
            <p className={css.buttonLine}>
              <a
                className={css.button}
                href="https://www.sl.nsw.gov.au/plan-your-visit"
              >
                Plan your Visit
              </a>
            </p>
          </div>
        </section>

        <section>
          <h3 className={css.sectionHeading}>
            <a href="https://www.sl.nsw.gov.au/opening-hours">
              View Opening Hours
            </a>
          </h3>
        </section>
      </div>
      <div className={css.column}>
        <section>
          <h3 className={css.sectionHeading}>
            <a href="https://www.sl.nsw.gov.au/newsletter-signup">
              Subscribe to newsletters
            </a>
          </h3>
        </section>

        <section>
          <p className={css.buttonLine}>
            <a className={css.button} href="https://www.sl.nsw.gov.au/news">
              Library News
            </a>{" "}
            <a className={css.button} href="https://www.sl.nsw.gov.au/blogs">
              Blogs
            </a>{" "}
            <a
              className={css.button}
              href="https://www.sl.nsw.gov.au/rss-feeds"
            >
              RSS
            </a>
          </p>
        </section>

        <section className={css.plsSection}>
          <p>
            We provide advice and support to all public libraries and local
            councils in NSW.
          </p>
          <p className={css.buttonLine}>
            <a
              className={css.button}
              href="https://www.sl.nsw.gov.au/public-library-services"
            >
              Public Library Services
            </a>
          </p>
        </section>

        <section className={css.copyrightSection}>
          <p>© 2019 by State Library of NSW.</p>
          <p>
            <img
              alt="Creative Commons Licence"
              title="CC BY 4.0 License"
              src="https://i.creativecommons.org/l/by/4.0/88x31.png"
              loading="lazy"
            />
          </p>
          <p>
            Free for reuse - unless otherwise stated, this content is licensed
            under{" "}
            <a href="http://creativecommons.org/licenses/by/4.0/">
              Creative Commons Attribution 4.0
            </a>
            .
          </p>
          {/*TODO fix XML namespace.*/}
          <p>
            You are free to copy, distribute, remix and build upon this content
            as long as you credit the author and the State Library of NSW as the
            source. For suggested attribution, see our{" "}
            <a
              href="https://www.sl.nsw.gov.au/copyright"
              rel="cc:morePermissions"
              xmlnscc="http://creativecommons.org/ns#"
            >
              copyright page
            </a>
            .
          </p>
        </section>
      </div>
      <div className={css.column}>
        <CollapseSection
          className={css.aboutSection}
          variant="dark"
          headingChildren={
            <h3 className={css.sectionHeading}>
              <a href="https://www.sl.nsw.gov.au/about-library">
                About the library
              </a>
            </h3>
          }
        >
          <nav role="navigation">
            <ul>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/access-inclusion">
                  <span>Access and inclusion</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/research-and-collections/ask-librarian">
                  <span>Ask a Librarian</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/awards">
                  <span>Awards</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/library-executive">
                  <span>Executive</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/fellows-and-residents">
                  <span>Fellowships</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/jobs-state-library">
                  <span>Jobs at the State Library</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/library-council">
                  <span>Library Council</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/legislation-and-policies">
                  <span>Policies</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/publications">
                  <span>Publications</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/services">
                  <span>Services</span>
                </a>
              </li>
              <li>
                <a href="https://www.sl.nsw.gov.au/about-library/venue-hire">
                  <span>Venue hire</span>
                </a>
              </li>
            </ul>
          </nav>
        </CollapseSection>

        <section>
          <p>
            <a
              href="https://www.sl.nsw.gov.au/plan-your-visit/access-inclusion"
              className={css.footerImageLink}
            >
              <img
                className={css.footerImage}
                alt="Accessing Sydney Collectively logo is an upper case letter A with a stylised human figure in the centre. A round head, arms outstretched, and body is wearing what looks like a dress. The figure is reaching out expansively with the word access next to the logo."
                title="Accessing Sydney Collectively"
                src="https://www.sl.nsw.gov.au/sites/all/themes/slnsw_frontend/images/accessing_sydney_logo.svg"
                loading="lazy"
              />
            </a>
          </p>
        </section>

        <section>
          <p>
            <a
              className={css.footerImageLink}
              href="https://www.nsw.gov.au/"
              rel="noopener noreferrer"
              target="_blank"
              title="NSW Government"
            >
              <img
                className={css.footerImage}
                alt="NSW Government logo"
                title="NSW Government"
                src="https://www.sl.nsw.gov.au/sites/all/themes/slnsw_frontend/images/nsw_white.svg"
                loading="lazy"
              />
            </a>
          </p>
        </section>
      </div>
    </div>
    <div className={css.bottomLinks}>
      <ul>
        <li>
          <a href="https://www.sl.nsw.gov.au/disclaimer">Disclaimer</a>
        </li>
        <li>
          <a href="https://www.sl.nsw.gov.au/privacy">Privacy</a>
        </li>
        <li>
          <a href="https://www.sl.nsw.gov.au/right-to-information">
            Right to Information
          </a>
        </li>
        <li>
          <a href="https://www.sl.nsw.gov.au/accessibility">Accessibility</a>
        </li>
        <li>
          <a href="https://www.sl.nsw.gov.au/copyright">Copyright</a>
        </li>
        <li>
          <a href="https://www.sl.nsw.gov.au/contact-us">Contact us</a>
        </li>
        <li>
          <a href="https://www.sl.nsw.gov.au/sitemap">Site map</a>
        </li>
      </ul>
    </div>
  </footer>
)

export default Footer;
