import React from "react"

import css from "./PageHeader.module.scss"

const PageHeader = ({ children }) => {
  return (
    <header className={css.pageHeader} role="heading" aria-level="1">
      <h1 className={css.pageTitle}>{children}</h1>
    </header>
  )
}

export default PageHeader;
