import React from "react"
import { Link } from "gatsby"

import css from "./Breadcrumb.module.scss"
import utilities from "../../styles/utilities.module.scss"

import breadcrumbBase from "../../config/breadcrumbBase"

const Breadcrumb = ({ route }) => {
  return (
    <section
      className={css.breadcrumbContainer}
      aria-label="Breadcrumb navigation"
    >
      <nav role="navigation">
        <p id="breadcrumb-label" className={utilities.screenreaderOnly}>
          You are here:
        </p>
        <ol className={css.breadcrumb} aria-labelledby="breadcrumb-label">
          <>
            {Object.keys(breadcrumbBase).map(key => (
              <li key={key}>
                <a href={breadcrumbBase[key].link}>
                  {breadcrumbBase[key].name}
                </a>
              </li>
            ))}
          </>
          {route.map((item, key) => {
            if (key === route.length - 1) {
              return (
                <li className="active" key={key}>
                  {item.name}
                </li>
              )
            } else {
              return (
                <li key={key}>
                  <Link to={item.href}>{item.name}</Link>
                </li>
              )
            }
          })}
        </ol>
      </nav>
    </section>
  )
}

export default Breadcrumb
